import "@aws-amplify/ui-react/styles.css";
import {
  Authenticator,
  Button,
  Heading,    
  View,
  Card,
} from "@aws-amplify/ui-react";

import React, {Component} from 'react';

import Layout from "./components/Layout/Layout";
import TestComponent from "./components/TestComponent";


import { MyAuthComponent } from "./components/MyAuthComponent";

import { GoogleApiProvider } from 'react-gapi'
import { MyYouTubeComponent } from "./components/MyYouTubeComponent";

function sendMessage()
{
  console.log('send a message');
}

class App extends Component {
  render() {
    return (
      <Layout>
        <Authenticator>
        {({signOut, user}) => (

          <View className="App">
            <Card>
              <Heading level={1}>Welcome to the test bed {user.username}!</Heading>
            </Card>
            <Button onClick={sendMessage}>Send a message</Button>
            <Button onClick={signOut}>Sign Out</Button>
            <GoogleApiProvider clientId={'368740469822-og1cj34s7t9b0p17g3j1gvd6g76n4a9e.apps.googleusercontent.com'}>
              <MyAuthComponent/>
              <MyYouTubeComponent/>          
            </GoogleApiProvider>
            <TestComponent />
          </View>

        )}
        </Authenticator>  
      </Layout>
    );
  }
}

export default App;