// src/components/MyDriveComponent.js
import { useGoogleApi } from 'react-gapi'

import {
  Button,   
} from "@aws-amplify/ui-react";


function uploadVideo() {
  

  // access the Drive API per gapi.client.drive
}

export function MyYouTubeComponent() {
  const gapi = useGoogleApi({
    discoveryDocs: [
      'https://developers.google.com/youtube/v3/docs/',
    ],
    scopes: [
      'https://www.googleapis.com/auth/youtube.upload',
    ],
  });

  if (!gapi) {
    return <div>Loading API</div>
  }
  else
  {
    return <div>
    <input type="file" id="video_file" name="video_file" accept="video/mp4"/>
                    <br/>
                    <Button onClick={uploadVideo}>Upload video</Button>
    </div>
  }
  // access the youtube api
}