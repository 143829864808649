import React, { Component} from 'react';

import {
  Heading,    
  View,
  Card,
  Button,   
} from "@aws-amplify/ui-react";

import { Amplify, Auth } from 'aws-amplify';

import AWS from 'aws-sdk';

import { APIGatewayClient, CreateApiKeyCommand } from "@aws-sdk/client-api-gateway";


const amplifyConfig = {
Auth: {

  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  identityPoolId: 'ap-southeast-2:f3ab648f-ced7-4627-a5f5-8d966bc2a466',

  // REQUIRED - Amazon Cognito Region
  region: 'ap-southeast-2',

  // OPTIONAL - Amazon Cognito Federated Identity Pool Region
  // Required only if it's different from Amazon Cognito Region
  identityPoolRegion: 'ap-southeast-2',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: 'ap-southeast-2_ABHIcClER',

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: '7c26sut1fkl93j9iie3bt283cr'
}
};

Amplify.configure(amplifyConfig);

async function sendMessage(){
    AWS.config.update({region: 'ap-southeast2'});

    const credentials = await Auth.currentUserCredentials();

    console.log(credentials);

    var sqs = new AWS.SQS({
        apiVersion: '2013-04-01',
        credentials: Auth.essentialCredentials(credentials)
        });

    let pressNumber = 0;

    // a client can be shared by different commands.
    const client = new APIGatewayClient({ region: "REGION" });

    const api_params = {
        
    };
    const command = new CreateApiKeyCommand(api_params);

    // async/await.
    try {
        const api_data = await client.send(command);
        console.log(api_data);
    // process data.
    } catch (error) {
    // error handling.
    } finally {
    // finally.
    }

    var params = {
    MessageAttributes: {
        "Title": {
        DataType: "String",
        StringValue: "The Whistler"
        },
        "Author": {
        DataType: "String",
        StringValue: "John Grisham"
        },
        "WeeksOn": {
        DataType: "Number",
        StringValue: "6"
        }
    },
    MessageBody: "Button pressed in the Ful10 test website.",
    MessageDeduplicationId: "Ful10_button_press_"+pressNumber,  // Required for FIFO queues
    MessageGroupId: "Group1",  // Required for FIFO queues
    QueueUrl: "https://sqs.ap-southeast-2.amazonaws.com/149082488303/CopyDataToS3Queue.fifo"
    };

    sqs.sendMessage(params, function(err, data) {
    if (err) {
        console.log("Error", err);
    } else {
        console.log("Success", data.MessageId);
    }
    });
}

async function receiveMessage(){
    AWS.config.update({region: 'ap-southeast2'});

    Auth.currentAuthenticatedUser().then(user => console.log(user));
    Auth.currentCredentials().then(creds => console.log(creds));
    Auth.currentUserCredentials().then(creds => console.log(creds));

    const credentials = await Auth.currentUserCredentials(); // currentCredentials();
    console.log(credentials);

    var sqs = new AWS.SQS({
        apiVersion: '2013-04-01',
        credentials: Auth.essentialCredentials(credentials)
        });

    var queueURL = "https://sqs.ap-southeast-2.amazonaws.com/149082488303/CopyDataToS3Queue.fifo";

    var params = {
    AttributeNames: [
        "SentTimestamp"
    ],
    MaxNumberOfMessages: 10,
    MessageAttributeNames: [
        "All"
    ],
    QueueUrl: queueURL,
    VisibilityTimeout: 20,
    WaitTimeSeconds: 0
    };

    sqs.receiveMessage(params, function(err, data) {
    if (err) {
        console.log("Receive Error", err);
    } else if (data.Messages) {
        console.log(data);
        if(data.Messages.length > 0){
            var deleteParams = {
                QueueUrl: queueURL,
                ReceiptHandle: data.Messages[0].ReceiptHandle
            };
            sqs.deleteMessage(deleteParams, function(err, data) {
                if (err) {
                    console.log("Delete Error", err);
                } else {
                    console.log("Message Deleted", data);
                }
            });
        }
    }
    });
}
/*
 gapi.load("client:auth2", function() {
          gapi.auth2.init({client_id: '368740469822-og1cj34s7t9b0p17g3j1gvd6g76n4a9e.apps.googleusercontent.com'});
        });

function authenticate() {
    return gapi.auth2.getAuthInstance()
        .signIn({scope: "https://www.googleapis.com/auth/yt-analytics.readonly"})
        .then(function() { console.log("Sign-in successful"); },
            function(err) { console.error("Error signing in", err); });
}
        
function loadClient() {
    return gapi.client.load("https://youtubeanalytics.googleapis.com/$discovery/rest?version=v2")
        .then(function() { console.log("GAPI client loaded for API"); },
            function(err) { console.error("Error loading GAPI client for API", err); });
}

async function uploadVideo(file){
    authenticate();

    var metadata = {
        snippet: {
            title: "Testing video upload",
            description: "A random video",
            categoryId: 22
        },
        status: {
            privacyStatus: "private",
            embeddable: true,
            license: "youtube"
        }
    }
    var uploader = new MediaUploader({
        baseUrl: "https://www.googleapis.com/upload/youtube/v3/videos",
        file: file,
        token: this.state.passedToken,
        metadata: metadata,
        id: 0,
        params: {
            part: Object.keys(metadata).join(",")
        },
        onError: function (data) {
            console.log("error", data)
        // onError code
        }.bind(this),
            onProgress: function (data) {
            console.log("Progress", data)
        // onProgress code
        }.bind(this),
            onComplete: function (data) {
            console.log("Complete", data)
        // onComplete code
        }.bind(this)
    });
    uploader.upload()
}
*/


class TestComponent extends Component {
    render() {
        return (
            <View className="testcomponent">
                <Card>
                    <Heading level={2}>This is a test component</Heading>
                    <Button onClick={sendMessage}>Press to send</Button>
                    <br/>
                    <Button onClick={receiveMessage}>Press to receive</Button>
                    <br/>
                    
                </Card>
            </View>
        );
    }
}

// <DocumentPicker onChange={uploadVideo}>Video</DocumentPicker>

export default TestComponent;